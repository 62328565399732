






















































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.promoteBox {
  .themeName {
    .itemLabel {
      min-width: 6rem;
    }
  }
  .state {
    .itemLabel {
      min-width: 6rem;
      // padding-right: 50px;
    }
  }
}
